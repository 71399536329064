import { DICTIONARY } from "./translation.constant";

export type MENU_ITEM_TYPE = {
  text: string;
  path: string;
  params?: string;
  auth: false;
  subs: MENU_ITEM_TYPE[];
};

export const ABOUT_US_SUBS: MENU_ITEM_TYPE[] = [
  {
    text: "Heritage",
    path: "/about-us",
    params: "?ref=heritage",
    auth: false,
    subs: [],
  },
  {
    text: "Philosophy",
    path: "/about-us",
    params: "?ref=philosophy",
    auth: false,
    subs: [],
  },
  {
    text: "Awards",
    path: "/about-us",
    params: "?ref=awards",
    auth: false,
    subs: [],
  },
  {
    text: "shop location",
    path: "/shop-location",
    auth: false,
    subs: [],
  },
];
export const OUR_BRANDS: MENU_ITEM_TYPE[] = [
  {
    text: DICTIONARY.SUB_MENU_CJLMXlB,
    path: "/cjlmxlb",
    auth: false,
    subs: [],
  },
  {
    text: "",
    path: "/jncj",
    auth: false,
    subs: [],
  },
  {
    text: DICTIONARY.SUB_MENU_CJJN,
    path: "/cjjn",
    auth: false,
    subs: [],
  },

  {
    text: DICTIONARY.SUB_MENU_CCK,
    path: "/cck",
    auth: false,
    subs: [],
  },
];
export const MENU: MENU_ITEM_TYPE[] = [
  {
    text: DICTIONARY.MENU_HOME,
    path: "/",
    auth: false,
    subs: [],
  },
  {
    text: DICTIONARY.MENU_ABOUT,
    path: "/about-us",
    auth: false,
    subs: [],
  },

  {
    text: DICTIONARY.MENU_OUR,
    path: "/",
    auth: false,
    subs: OUR_BRANDS,
  },
  {
    text: DICTIONARY.MENU_PROMOTION,
    path: "/news-and-promotion",
    auth: false,
    subs: [],
  },
  {
    text: DICTIONARY.MENU_MEMBERSHIP,
    path: "/membership",
    auth: false,
    subs: [],
  },
  {
    text: DICTIONARY.MENU_BRANCH,
    path: "/shop-location",
    auth: false,
    subs: [],
  },
  {
    text: DICTIONARY.MENU_CAREER,
    path: "/join-us",
    auth: false,
    subs: [],
  },
  {
    text: DICTIONARY.MENU_CONTACT,
    path: "/contact-us",
    auth: false,
    subs: [],
  },
];
