export const LANGUAGES = [
  {
    VALUE: "zh-HK",
    DISPLAY: "繁",
  },
  {
    VALUE: "en-US",
    DISPLAY: "ENG",
  },
  {
    VALUE: "zh-CN",
    DISPLAY: "簡",
  },
];
