/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import * as React from "react";
import { useTranslation } from "next-i18next";
import AppContainer from "../AppContainer";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { gotoPage } from "@/utils/helpers/common/common.helpers";
import { settingApi } from "@/utils/api";
import { IGetCompanyReferencesResponse } from "@/utils/api/setting";
import { useEffect, useState } from "react";
import { useIsMounted } from "usehooks-ts";
import useStyles from "./Footer.style";
import { DICTIONARY } from "@/utils/constants/translation.constant";

export interface IAppProps {}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#190425" : "#190425",
  ...theme.typography.body2,
  //padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
export default function Footer(props: IAppProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const { locale } = router;
  const { classes } = useStyles();
  const [companyReferences, setCompanyReferences] =
    useState<IGetCompanyReferencesResponse | null>(null);

  let getCompanyReferences = async () => {
    try {
      let res = await settingApi.getCompanyReferences({
        params: {},
        headers: {
          Language: locale ? locale.replace("-", "_") : undefined,
        },
      });
      if (res.status === 200) {
        setCompanyReferences(res.data.params);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let FetchData = async () => {
    await getCompanyReferences();
  };

  useEffect(() => {
    if (!isMounted()) {
      FetchData();
    }
  }, []);

  const isMounted = useIsMounted();

  return (
    <>
      <div className={classes.bodyFT}>
        <AppContainer>
          <img
            src="/img/footer/logoFT.png"
            alt="Your Image"
            className={classes.logoFT}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className={classes.girdFT}
          >
            <Grid item xs={12} md={6}>
              <Item>
                <div className={classes.GruopIcon}>
                  <span className={classes.textFT}>
                    {t(DICTIONARY.FOLLOW_US)}
                  </span>

                  <button
                    className={classes.iconFT}
                    onClick={() =>
                      window.open(
                        companyReferences && companyReferences.facebook
                          ? companyReferences.facebook
                          : "",
                        "_blank"
                      )
                    }
                  >
                    <img src="/icon/facebook.svg" />
                  </button>

                  <button
                    className={classes.iconFT}
                    onClick={() =>
                      window.open(
                        companyReferences && companyReferences.IG
                          ? companyReferences.IG
                          : "",
                        "_blank"
                      )
                    }
                  >
                    <img src="/icon/insta.svg" />
                  </button>

                  {/* <button className={classes.iconFT}>
                    <img src="/icon/cart.svg" />
                  </button> */}
                  <a onClick={() => gotoPage("/join-us")}>
                    <button className={classes.iconFT}>
                      <img src="/icon/briefCase.svg" alt="bag Icon" />
                    </button>
                  </a>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                item
                xs={12}
                style={{
                  float: "right",
                }}
              >
                <Grid item xs={6} md={0}>
                  <Item></Item>
                </Grid>
                <Grid item xs={6} md={0}>
                  <Item></Item>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Item>
                    <a
                      onClick={() => gotoPage("contact-us")}
                      className={classes.textMenu}
                    >
                      {t(DICTIONARY.MENU_CONTACT)}
                    </a>
                  </Item>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Item>
                    <a
                      onClick={() => gotoPage("/join-us")}
                      className={classes.textMenu}
                    >
                      {t(DICTIONARY.MENU_CAREER)}
                    </a>
                  </Item>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Item>
                    <a
                      onClick={() => gotoPage("terms-and-conditions")}
                      className={classes.textMenu}
                    >
                      {t(DICTIONARY.TERM)}
                    </a>
                  </Item>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Item>
                    <a
                      onClick={() => gotoPage("privacy-and-policy")}
                      className={classes.textMenu}
                    >
                      {t(DICTIONARY.POLICY)}
                    </a>
                  </Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item>
                <div className={classes.GroupImg}>
                  <button
                    className={classes.iconFT}
                    onClick={() =>
                      window.open(
                        companyReferences &&
                          companyReferences.apple_store_app_link
                          ? companyReferences.apple_store_app_link
                          : "",
                        "_blank"
                      )
                    }
                  >
                    <img src="/icon/App.svg" />
                  </button>
                  <button
                    className={classes.iconFT}
                    onClick={() =>
                      window.open(
                        companyReferences &&
                          companyReferences.google_play_app_link
                          ? companyReferences.google_play_app_link
                          : "",
                        "_blank"
                      )
                    }
                  >
                    <img src="/icon/Google.svg" />
                  </button>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item className={classes.ItemCryFT}>
                <p className={classes.CrystalFT}>
                  {locale !== "en-US" ? (
                    <>
                      {t(DICTIONARY.TEXT_FOOTER)} {new Date().getFullYear()}
                    </>
                  ) : (
                    <>
                      {t(DICTIONARY.TEXT_FT_FIRST)}
                      {new Date().getFullYear()}
                      {t(DICTIONARY.TEXT_FT_LASTER)}
                    </>
                  )}
                </p>
              </Item>
            </Grid>
          </Grid>
        </AppContainer>
      </div>
    </>
  );
}
