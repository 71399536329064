/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import * as React from "react";
import { useTranslation } from "next-i18next";
import AppContainer from "../AppContainer";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTrackPosition } from "@/hooks";
import useStyles from "./Header.style";
import { LANGUAGES } from "@/utils/constants/common.constant";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
} from "@mui/material";
import { gotoPage } from "@/utils/helpers/common";
import { menuConstants } from "@/utils/constants";
import dynamic from "next/dynamic";
import { settingApi } from "@/utils/api";
import { useIsMounted } from "usehooks-ts";
import { IGetCompanyReferencesResponse } from "@/utils/api/setting";
import { DICTIONARY } from "@/utils/constants/translation.constant";

const simple = (props: any) => (
  <React.Fragment>{props.children}</React.Fragment>
);
const NoSsr = dynamic(() => Promise.resolve(simple), {
  ssr: false,
});

export interface IAppProps {}

export default function Header(props: IAppProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const router = useRouter();
  const { i18n } = useTranslation();
  const { pathname, asPath, query } = router;
  const { locale } = router;
  const menuRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentPosition, setCurrentPosition] = useState(0);
  const [isDown, setIsDown] = useState<boolean>(false);
  const position = useTrackPosition();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRotated, setIsRotated] = useState<boolean[]>(
    Array(menuConstants.MENU.length).fill(false)
  );
  const [companyReferences, setCompanyReferences] =
    useState<IGetCompanyReferencesResponse | null>(null);

  const handleButtonClick = (index: number, langValue: string) => {
    onTranslate(langValue);
  };

  const handleIconClick = (index: number) => {
    const updatedRotated = [...isRotated];
    updatedRotated[index] = !updatedRotated[index];
    setIsRotated(updatedRotated);
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  let onTranslate = (lang: string) => {
    if (lang == i18n.language) return;
    if (typeof window !== "undefined")
      (window as any).NextPublic.lang = lang as any;
    router.push({ pathname, query }, asPath, { locale: lang });
  };

  useEffect(() => {
    setIsDown(position > currentPosition);
    setCurrentPosition(position);
  }, [position]);
  let getCompanyReferences = async () => {
    try {
      let res = await settingApi.getCompanyReferences({
        params: {},
        headers: {
          Language: locale ? locale.replace("-", "_") : undefined,
        },
      });
      if (res.status === 200) {
        setCompanyReferences(res.data.params);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let FetchData = async () => {
    await getCompanyReferences();
  };

  useEffect(() => {
    if (!isMounted()) {
      FetchData();
    }
  }, []);

  const isMounted = useIsMounted();

  return (
    <div>
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "10",
          backgroundColor: `${
            position === 0 || isPopupOpen
              ? "transparent"
              : "rgba(51, 51, 51, 0.80)"
          }`,
        }}
        className={`${isDown ? "hidden" : "block"}`}
        ref={menuRef}
      >
        <AppContainer>
          <div className={!isPopupOpen ? classes.header : classes.header2}>
            {!isMobile ? (
              <>
                <button
                  className={
                    !isPopupOpen ? classes.iconDehaze : classes.hideHeader
                  }
                  onClick={togglePopup}
                >
                  <img src="/icon/dehaze.svg" alt="Dehaze Icon" />
                </button>

                <div
                  className={!isPopupOpen ? classes.img : classes.hideHeader}
                >
                  <img
                    src="/img/header/one.svg"
                    alt="Image One"
                    className={classes.imgOne}
                  />
                </div>
                <img
                  src="/img/header/two.svg"
                  alt="Image Two"
                  className={
                    !isPopupOpen ? classes.imgOne2 : classes.hideHeader
                  }
                  onClick={() => gotoPage("/")}
                />
                <NoSsr>
                  <div
                    className={
                      !isPopupOpen ? classes.divButton : classes.hideHeader
                    }
                  >
                    {LANGUAGES.map((lang, index) => {
                      return (
                        <button
                          key={index}
                          className={`${classes.button} ${
                            locale === lang.VALUE ? classes.clickableButton : ""
                          }`}
                          onClick={() => handleButtonClick(index, lang.VALUE)}
                        >
                          {`${lang.DISPLAY} ${
                            index < LANGUAGES.length - 1 ? "\u00a0/\u00a0" : ""
                          }`}
                        </button>
                      );
                    })}
                  </div>
                </NoSsr>

                <div
                  className={
                    !isPopupOpen ? classes.divIcon : classes.hideHeader
                  }
                >
                  <button
                    className={classes.icon}
                    onClick={() =>
                      window.open(
                        companyReferences && companyReferences.facebook
                          ? companyReferences.facebook
                          : "",
                        "_blank"
                      )
                    }
                  >
                    <img src="/icon/facebook.svg" alt="FB Icon" />
                  </button>

                  <button
                    className={classes.icon}
                    onClick={() =>
                      window.open(
                        companyReferences && companyReferences.IG
                          ? companyReferences.IG
                          : "",
                        "_blank"
                      )
                    }
                  >
                    <img src="/icon/insta.svg" alt="Ins Icon" />
                  </button>

                  {/* <button className={classes.icon}>
                    <img src="/icon/cart.svg" alt="Cart Icon" />
                  </button> */}
                  <a onClick={() => gotoPage("/join-us")}>
                    <button className={classes.icon2}>
                      <img src="/icon/briefCase.svg" alt="bag Icon" />
                    </button>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className={classes.divButton2}>
                  <Grid container rowSpacing={1}>
                    {!isPopupOpen && (
                      <Grid item xs={0}>
                        <button
                          className={classes.iconDehaze}
                          onClick={togglePopup}
                        >
                          <img src="/icon/dehaze.svg" alt="Dehaze Icon" />
                        </button>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={10}
                      md={11}
                      style={{
                        justifyContent: "center",
                        margin: "auto",
                        display: isSmall ? "block" : "flex",
                      }}
                      gap={1}
                    >
                      <Grid item xs={12} md={6} sx={{ paddingBottom: "10px" }}>
                        <img
                          src="/img/header/one.svg"
                          alt="Image One"
                          className={!isPopupOpen ? "" : classes.hideHeader}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <img
                          src="/img/header/logo.svg"
                          alt="Image Two"
                          style={{
                            display: !isPopupOpen ? "block" : "none",

                            padding: 0,
                          }}
                          onClick={() => gotoPage("/")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        </AppContainer>
      </div>
      {isMobile ? (
        <>
          {isPopupOpen && (
            <div className={classes.popup}>
              <div className={classes.scrollableContainer}>
                <div>
                  <button
                    onClick={togglePopup}
                    style={{ float: "right", padding: "16px 16px" }}
                  >
                    <img src="/icon/close.svg" alt="close Icon" />
                  </button>
                </div>

                {menuConstants.MENU.map((menuItem, index) => (
                  <div key={index}>
                    <Accordion
                      key={index}
                      style={{
                        background: "none",
                        boxShadow: "none",
                        margin: 0,
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                        onClick={
                          menuItem.subs.length > 0
                            ? () => handleIconClick(index)
                            : () => gotoPage(menuItem.path)
                        }
                      >
                        <Typography className={classes.heading}>
                          {t(menuItem.text)}
                          {Array.isArray(menuItem.subs) &&
                            menuItem.subs.length > 0 && (
                              <span className={classes.iconChevron}>
                                {isRotated[index] ? (
                                  <img
                                    src="/img/header/right-show.svg"
                                    alt="icon chevron"
                                  />
                                ) : (
                                  <img
                                    src="/img/header/right-close.svg"
                                    alt="icon chevron"
                                  />
                                )}
                              </span>
                            )}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: "0 8px" }}>
                        <Grid container direction="column">
                          {menuItem.subs &&
                            menuItem.subs.map((subItem, subIndex) => (
                              <ListItem
                                key={subIndex}
                                style={{
                                  padding: "16px 15%",
                                }}
                                button
                              >
                                <div
                                  onClick={() => {
                                    gotoPage(subItem.path, subItem.params);
                                  }}
                                >
                                  <Typography className={classes.menuSmall}>
                                    {subItem.text === "" ? (
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {t(DICTIONARY.SUB_MENU_JN)}
                                        {locale === "en-US" ? (
                                          ""
                                        ) : (
                                          <>
                                            <span
                                              style={{
                                                transform: "translateY(-25%)",
                                                padding: "0 5px",
                                              }}
                                            >
                                              .
                                            </span>{" "}
                                            {t(DICTIONARY.SUB_MENU_JNS)}
                                          </>
                                        )}
                                      </p>
                                    ) : (
                                      t(subItem.text)
                                    )}
                                  </Typography>
                                </div>
                              </ListItem>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6} className={classes.GridIcon}>
                        <Grid item xs="auto">
                          <button
                            className={classes.icon}
                            onClick={() =>
                              window.open(
                                companyReferences && companyReferences.facebook
                                  ? companyReferences.facebook
                                  : "",
                                "_blank"
                              )
                            }
                          >
                            <img src="/icon/facebook.svg" alt="FB Icon" />
                          </button>
                        </Grid>
                        <Grid item xs="auto">
                          <button
                            className={classes.icon}
                            onClick={() =>
                              window.open(
                                companyReferences && companyReferences.IG
                                  ? companyReferences.IG
                                  : "",
                                "_blank"
                              )
                            }
                          >
                            <img src="/icon/insta.svg" alt="Ins Icon" />
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className={classes.gridLanguages}>
                    <NoSsr>
                      <div className={classes.divButton}>
                        {LANGUAGES.map((lang, index) => {
                          return (
                            <button
                              key={index}
                              className={`${classes.button} ${
                                locale === lang.VALUE
                                  ? classes.clickableButton
                                  : ""
                              }`}
                              onClick={() =>
                                handleButtonClick(index, lang.VALUE)
                              }
                            >
                              {`${lang.DISPLAY} ${
                                index < LANGUAGES.length - 1
                                  ? "\u00a0/\u00a0"
                                  : ""
                              }`}
                            </button>
                          );
                        })}
                      </div>
                    </NoSsr>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isPopupOpen && (
            <div className={classes.popup}>
              <div className={classes.scrollableContainer}>
                <div>
                  <button
                    onClick={togglePopup}
                    style={{ float: "right", padding: "16px 16px" }}
                  >
                    <img src="/icon/close.svg" alt="close Icon" />
                  </button>
                </div>
                {menuConstants.MENU.map((menuItem, index) => (
                  <div key={index}>
                    <Accordion
                      key={index}
                      style={{
                        background: "none",
                        boxShadow: "none",
                        margin: 0,
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                        onClick={
                          menuItem.subs.length > 0
                            ? () => handleIconClick(index)
                            : () => gotoPage(menuItem.path)
                        }
                      >
                        <Typography className={classes.heading}>
                          {t(menuItem.text)}
                          {Array.isArray(menuItem.subs) &&
                            menuItem.subs.length > 0 && (
                              <span className={classes.iconChevron}>
                                {isRotated[index] ? (
                                  <img
                                    src="/img/header/right-show.svg"
                                    alt="icon chevron"
                                  />
                                ) : (
                                  <img
                                    src="/img/header/right-close.svg"
                                    alt="icon chevron"
                                  />
                                )}
                              </span>
                            )}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: "0 8px" }}>
                        <Grid container direction="column">
                          {menuItem.subs &&
                            menuItem.subs.map((subItem, subIndex) => (
                              <ListItem
                                key={subIndex}
                                style={{
                                  padding: "16px 15%",
                                }}
                                button
                              >
                                <div
                                  onClick={() =>
                                    gotoPage(subItem.path, subItem.params)
                                  }
                                >
                                  <Typography className={classes.menuSmall}>
                                    {subItem.text === "" ? (
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {t(DICTIONARY.SUB_MENU_JN)}
                                        {locale === "en-US" ? (
                                          ""
                                        ) : (
                                          <>
                                            <span
                                              style={{
                                                transform: "translateY(-25%)",
                                                padding: "0 5px",
                                              }}
                                            >
                                              .
                                            </span>{" "}
                                            {t(DICTIONARY.SUB_MENU_JNS)}
                                          </>
                                        )}
                                      </p>
                                    ) : (
                                      t(subItem.text)
                                    )}
                                  </Typography>
                                </div>
                              </ListItem>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6} className={classes.GridIcon}>
                      <Grid item xs="auto">
                        <button
                          className={classes.icon}
                          onClick={() =>
                            window.open(
                              companyReferences && companyReferences.facebook
                                ? companyReferences.facebook
                                : "",
                              "_blank"
                            )
                          }
                        >
                          <img src="/icon/facebook.svg" alt="FB Icon" />
                        </button>
                      </Grid>
                      <Grid item xs="auto">
                        <button
                          className={classes.icon}
                          onClick={() =>
                            window.open(
                              companyReferences && companyReferences.IG
                                ? companyReferences.IG
                                : "",
                              "_blank"
                            )
                          }
                        >
                          <img src="/icon/insta.svg" alt="Ins Icon" />
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
