import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import {
  ICreateApplicationFormPayload,
  IGetListBranchPayload,
  IGetListCenterPayload,
  IGetListReferencePayload,
  IGetListRegionPayload,
  IGetListShopLocationPayload,
} from "./shopLocation.api.types";

const shopLocationApi = {
  getListShopLocation: (payload: IGetListShopLocationPayload) => {
    return commonAxios.get<AxiosResponseData>("/branches/search.json", {
      params: payload.params,
      headers: payload.headers,
      cancelToken: payload.cancelToken,
    });
  },
  getListRegion: (payload: IGetListRegionPayload) => {
    return commonAxios.get<AxiosResponseData>("/regions/getRegion.json", {
      params: payload.params,
      headers: payload.headers,
      cancelToken: payload.cancelToken,
    });
  },
  getListCenter: (payload: IGetListCenterPayload) => {
    return commonAxios.get<AxiosResponseData>("/centers/getCenter.json", {
      params: payload.params,
      headers: payload.headers,
      cancelToken: payload.cancelToken,
    });
  },
  getListBranch: (payload: IGetListBranchPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "/branches/getList.json",

      {
        params: payload.params,
        headers: payload.headers,
        cancelToken: payload.cancelToken,
      }
    );
  },
  getListReference: (payload: IGetListReferencePayload) => {
    return commonAxios.get<AxiosResponseData>(
      "/applicationForms/getListReference.json",
      {
        params: payload.params,
        headers: payload.headers,
        cancelToken: payload.cancelToken,
      }
    );
  },
  createApplicationForm: (payload: ICreateApplicationFormPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "/applicationForms/createApplicationForm.json",
      payload.params
    );
  },
};

export default shopLocationApi;
