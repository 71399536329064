import { CancelToken } from "axios";
import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import {
  IGetCompanyReferencesPayload,
  IGetFilePayload,
  IGetFooterPayload,
} from "./setting.api.types";

const settingApi = {
  getCompanyReferences: (payload: IGetCompanyReferencesPayload) => {
    return commonAxios.get<AxiosResponseData>("/companies/getItem.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
  getFile: (payload: IGetFilePayload) => {
    return commonAxios.get<AxiosResponseData>(
      "/MembershipLeaflets/getFile.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers,
      }
    );
  },
  getFooter: (payload: IGetFooterPayload) => {
    return commonAxios.get<AxiosResponseData>("/footers/getFooter.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
};

export default settingApi;
