export const DICTIONARY = {
  ADDRESS: "address",
  CRM_APP_INTRODUCTION:
    "Access your Crystal Jade’s Jadeite membership account (Check your JPoints balance, transactions record and/or view available e-vouchers) at your convenience. Make a reservation at your preferred outlet or shop for our popular ready-to-eat dim sum, festive goodies, receive latest updates on promotions and more.",
  APP_STORE_DOWNLOAD: "APP STORE DOWNLOAD",
  BACK: "back",
  BOOK_NOW: "book now",
  Branch_Information: "Branch Information",
  BRANCH_LOCATION: "branch location",
  CONTACT: "contact",
  CONTACT_US: "contact us",
  CONTENT: "Content",
  DATE: "Date",
  DEPARTMENT: "Department",
  DETAIL: "detail",
  GENERAL: "general",
  GOLD_MEMBER: "gold member",
  MEMBERSHIP: "membership",
  GOOGLE_PLAY_DOWNLOAD: "GOOGLE PLAY DOWNLOAD",
  EMAIL: "Email",
  FIRST_NAME: "First name",
  FRANCHISE_OPPORTUNITY: "Franchise Opportunity",
  FRONTLINE_RECRUITMENT: "Frontline Recruitment",
  GENERAL_INQUIRY: "General Inquiry",
  CONTACT_US_FORM_CONFIRM: "CONTACT_US_FORM_CONFIRM",
  RECRUITMENT_MORE_INFO:
    "If you want to be part of a passionate team that believes in delivering only the best, send us an email at hr@crystaljade.com.hk with your detailed resume along with your desired job position and expected remuneration. Or give us a call at +852 26863537/26863538",
  LAST_NAME: "Last name",
  LATEST_PROMOTION: "Latest Promotion",
  LEARN_MORE: "Learn more",
  MEMBER_TIER: "member tier",
  OFFICE_RECRUITMENT: "Office Recruitment",
  OPENING_HOURS: "opening hours",
  OTHER_PROMOTIONS: "other promotions",
  PAST_EVENTS: "Past Events",
  CONTACT_US_INTRODUCTION:
    "Please complete the form below and submit your inquiry. We will get back to you within 3-5 working days.",
  POSITION: "Position",
  PRIVACY_POLICY: "Privacy Policy",
  PURPLE_MEMBER: "purple member",
  REGION: "region",
  RESTAURANT_BRAND: "restaurant brand",
  SEE_MORE: "See more",
  SELECT: "select",
  SUBMIT: "submit",
  TAKEAWAY: "takeaway",
  TERM_AND_CONDITIONS: "Terms & Conditions",
  TIER: "tier",
  VACANCIES: "vacancies",
  FRANCHISE_OPPORTUNITY_DETAIL:
    "We are looking for enthusiastic business partners to participate in our business expansion plans in local and overseas markets. For more details, please email us at franchise@crystaljade.com.",
  RECRUITMENT_WELCOME:
    "Welcome to inquire directly, or fill in the application form, and a recruiter will contact you later",
  PHONE: "phone",
  TAKEAWAY_LINE: "takeaway line",
  MENU_HOME: "Home",
  MENU_ABOUT: "About",
  MENU_BRANCH: "Branch",
  MENU_OUR: "Our",
  MENU_PROMOTION: "Promotion",
  MENU_MEMBERSHIP: "Membership",
  MENU_CAREER: "Career",
  MENU_CONTACT: "Contact",
  SUB_MENU_CCK: "Cck",
  SUB_MENU_CJLMXlB: "Cjlmxlb",
  SUB_MENU_JN: "Jn",
  SUB_MENU_JNS: "JnSub",
  SUB_MENU_CJJN: "Cjjn",
  TERM: "Terms",
  POLICY: "Policy",
  TEXT_FOOTER: "Text footer",
  FOLLOW_US: "followUs",
  DOWNLOAD_MEMBERSHIP: "Download membership leaflet",
  TITLE_CRM: "title_CRM",
  TEXT_FT_FIRST: "Text_footer_en_first",
  TEXT_FT_LASTER: "Text_footer_en_laster",
  MENU_APPLICATION: "application",
  //APP FROM
  TITLE_APP_FORM: "TITLE_APP_FORM",
  CONTENT_APP_FORM: "CONTENT_APP_FORM",
  LABEL_DATE: "LABEL_DATE",
  PLACEHOLDER_DATE: "PLACEHOLDER_DATE",
  LABEL_POSITION: "LABEL_POSITION",
  PLACEHOLDER_POSITION: "PLACEHOLDER_POSITION",
  LABEL_NAME: "LABEL_NAME",
  PLACEHOLDER_NAME: "PLACEHOLDER_NAME",
  LABEL_AGE: "LABEL_AGE",
  PLACEHOLDER_AGE: "PLACEHOLDER_AGE",
  LABEL_PHONE: "LABEL_PHONE",
  PLACEHOLDER_PHONE: "PLACEHOLDER_PHONE",
  LABEL_EXP_YEAR: "LABEL_EXP_YEAR",
  PLACEHOLDER_EXP_YEAR: "PLACEHOLDER_EXP_YEAR",
  NOTE_DETAIL_EXP: "NOTE_DETAIL_EXP",
  LABEL_DETAIL_EXP: "LABEL_DETAIL_EXP",
  PLACEHOLDER_DETAIL_EXP: "PLACEHOLDER_DETAIL_EXP",
  LABEL_LIVE_AREA: "LABEL_LIVE_AREA",
  PLACEHOLDER_LIVE_AREA: "PLACEHOLDER_LIVE_AREA",
  LABEL_DATE_FOR_WORK: "LABEL_DATE_FOR_WORK",
  PLACEHOLDER_DATE_FOR_WORK: "PLACEHOLDER_DATE_FOR_WORK",
  LABEL_TIME_FOR_WORK: "LABEL_TIME_FOR_WORK",
  PLACEHOLDER_TIME_FOR_WORK: "PLACEHOLDER_TIME_FOR_WORK",
  LABEL_SALARY: "LABEL_SALARY",
  PLACEHOLDER_SALARY: "PLACEHOLDER_SALARY",
  LABEL_PREFERRED_REGION: "LABEL_PREFERRED_REGION",
  PLACEHOLDER_PREFERRED_REGION: "PLACEHOLDER_PREFERRED_REGION",
  PLACEHOLDER_BRANCH: "PLACEHOLDER_BRANCH",
  LABEL_SURVEY: "LABEL_SURVEY",
  PLACEHOLDER_SURVEY: "PLACEHOLDER_SURVEY",
  INFO_CONTACT: "INFO_CONTACT",
};
