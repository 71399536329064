import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import { IGetListMembershipTierPayload } from "./membershipTier.api.types";

const membershipTierApi = {
  getListMembershipTier: (payload: IGetListMembershipTierPayload) => {
    return commonAxios.get<AxiosResponseData>("/memberTierInformations/getList.json", 
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers
      }
    );
  },
};

export default membershipTierApi;
