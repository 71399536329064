import React, { ReactNode, useMemo } from "react";

import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";

type MainProps = {
  children: React.ReactNode;
};

const Main = ({ children }: MainProps) => {
  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Header />
      <main style={{ minHeight: "100vh" }}>{children}</main>
      <Footer />
    </div>
  );
};

export default Main;
