import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "Header",
  uniqId: "jehdj",
})((theme) => {
  return {
    header: {
      display: "flex",
      margin: "auto",
      padding: "3% 0",
      height: "fit-content",

      [theme.breakpoints.down("sm")]: {
        display: "grid",
      },
    },
    hideHeader: {
      display: "none",
    },
    header2: {
      display: "flex",
      margin: "auto",
      padding: "3% 0",
      height: "fit-content",

      [theme.breakpoints.down("lg")]: {
        display: "grid",
        float: "right",
      },
    },
    headerPopUp: {
      display: "flex",
      margin: "auto",
      padding: "3% 0",
      height: "fit-content",

      [theme.breakpoints.down("lg")]: {
        display: "grid",
        float: "left",
      },
    },

    button: {
      flex: 1,
      fontSize: 14,
      textAlign: "center",
      border: "none",
      color: "white",
      opacity: "0.6",
      cursor: "pointer",
      fontWeight: "700",
    },

    divButton: {
      borderRight: "2px solid white",
      margin: "auto",
      padding: "0 15px",
      marginRight: "0",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "0",
        borderRight: "none",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
        borderRight: "none",
      },
    },
    divButton2: {
      borderRight: "2px solid white",
      margin: "auto",
      marginRight: "0",
      padding: "0 15px",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "0",
        borderRight: "none",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
        borderRight: "none",
        padding: "0",
      },
    },
    clickableButton: {
      color: "white",
      opacity: "1",
    },
    divIcon: {
      margin: "5px",
    },
    icon: {
      flex: 1,
      padding: 10,
      fontSize: 14,
      textAlign: "center",
      border: "none",
      cursor: "pointer",
    },
    icon2: {
      flex: 1,
      padding: "10px 0px 10px 10px",
      fontSize: 14,
      textAlign: "center",
      border: "none",
      cursor: "pointer",
    },
    iconDehaze: {
      marginRight: "15px",
      marginTop: "5px",
    },
    imgOne: {
      marginRight: "5%",

      [theme.breakpoints.down("sm")]: {
        marginRight: "0",
        margin: "auto",
        paddingTop: "10px",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "20px",
        display: "contents",
      },
    },
    imgOne2: {
      margin: "0 auto",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        marginRight: "0",
        margin: "auto",
        paddingTop: "10px",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "20px",
        display: "contents",
      },
    },
    divLanguages: {
      gap: "10px",
      margin: "auto",
    },
    img: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    popup: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "25%",
      height: "100%",
      background: "rgba(51, 51, 51, 0.80)",
      display: "flex",
      justifyContent: "center",
      alignTtems: "center",
      zIndex: "1000",
      [theme.breakpoints.down("lg")]: {
        width: "30%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    buttonClose: {
      margin: "-65px 0 0 0 ",
      [theme.breakpoints.down("lg")]: {
        margin: "-85px 0px 0 100px ",
      },
      [theme.breakpoints.down("md")]: {
        margin: "-60px 0 0 0 ",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "-50px 0 0 0 ",
      },
    },
    panelSummary: {
      flexDirection: "row-reverse",
      paddingLeft: "0px",
    },
    heading: {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "25px",
      color: "#fff",
      textTransform: "uppercase",
      width: "100%",
      paddingLeft: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    innerMenuItem: { justifyContent: "center" },
    expanded: {
      padding: "0px",
    },
    textMenu: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      color: "#fff",
      textTransform: "uppercase",
      padding: "12px 0",
      textAlign: "center",
      fontWeight: "500",
    },

    menuSmall: {
      fontFamily: "Montserrat",
      fontSize: "22px",
      color: "#fff",
      textTransform: "uppercase",
      justifyContent: "center",
      fontWeight: "500",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    scrollableContainer: {
      position: "absolute",
      inset: "35px 0px 0px",
      overflowY: "scroll",
      display: "-webkit-flex;",
      flexDirection: "column",
      maxHeight: "calc(100vh - 50px)",
      scrollbarWidth: "thin",
      scrollbarColor: "transparent transparent",
      [theme.breakpoints.down("md")]: {
        inset: "10px 0px 0px",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    },
    iconChevron: {
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: "16px",
      float: "right",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    GridIcon: {
      display: "flex",
      padding: "26px",
      justifyContent: "flex-start",
    },
    gridLanguages: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  };
});

export default useStyles;
