import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "Footer",
  uniqId: "footer",
})((theme) => {
  return {
    bodyFT: {
      backgroundColor: "#190425",
      width: "100%",
    },
    logoFT: {
      margin: "auto",
      paddingTop: "65px",
      paddingBottom: "40px",
    },
    textFT: {
      color: "white",
      position: "relative",
      top: "-6px",
      fontSize: 18,
      textAlign: "center",
      marginLeft: "10px",
      textTransform: "uppercase",
    },
    iconFT: {
      flex: 1,
      marginLeft: "10px",
      cursor: "pointer",
    },

    girdFT: {
      marginTop: "20px",
      paddingBottom: "20px",
    },
    menuFT: {
      textAlign: "end",
    },
    textMenu: {
      color: "white",
      fontSize: 17,

      textTransform: "uppercase",
      fontWeight: 300,
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "center",
        fontSize: 15,
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        fontSize: 13,
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    CrystalFT: {
      color: "white",
      fontSize: 18,
      marginLeft: "10px",
      fontWeight: 300,
      textAlign: "end",
      [theme.breakpoints.down("lg")]: {
        textAlign: "center",
        fontSize: 16,
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: 13,
      },
    },
    GruopIcon: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    GroupImg: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: "100%",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        display: "block",
        width: "100%",
      },
    },
    ItemCryFT: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: "100%",
      float: "right",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        float: "none",
      },
    },
  };
});

export default useStyles;
