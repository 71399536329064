import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import {
  IDescriptionForFeedbackPayload,
  ISendFeedbackPayload,
} from "./feedback.api.types";

const feedbackApi = {
  sendFeedback: (payload: ISendFeedbackPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "/feedbacks/insertFeedback.json",
      payload.params
    );
  },
  getDescriptionForFeedback: (payload: IDescriptionForFeedbackPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "/descriptionForFeedbacks/getDescriptionForFeedback.json",
      {
        params: payload.params,
      }
    );
  },
};

export default feedbackApi;
