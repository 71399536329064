import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import { IGetListFrontlineRecruitmentPayload, IGetListOfficeRecruitmentPayload, IGetRecruitmentContactPayload, IGetRecruitmentDetailPayload } from "./vacancies.api.types";

const settingApi = {
  getListOfficeRecruitment: (payload: IGetListOfficeRecruitmentPayload) => {
    return commonAxios.get<AxiosResponseData>("/recruitments/getListOffice.json", 
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers
      }
    );
  },
  getListFrontlineRecruitment: (payload: IGetListFrontlineRecruitmentPayload) => {
    return commonAxios.get<AxiosResponseData>("/recruitments/getListFrontLine.json", 
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers
      }
    );
  },
  getRecruitmentDetail: (payload: IGetRecruitmentDetailPayload) => {
    return commonAxios.get<AxiosResponseData>("/recruitments/getDetail.json", 
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers
      }
    );
  },
  getRecruitmentContact: (payload: IGetRecruitmentContactPayload) => {
    return commonAxios.get<AxiosResponseData>("/recruitmentContacts/getRecruitmentContact.json", 
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers
      }
    );
  },
};

export default settingApi;
